import { motion } from 'framer-motion'

const Footer = () => {
  return (
    <motion.footer initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.4 }}>
      <div className="text-center bg-gray-dark text-white p-3">
        &copy; 2021 Nemzeti Művelődési Intézet
      </div>
    </motion.footer>
  )
}

export default Footer