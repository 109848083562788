import { createContext } from 'react'

const Data = createContext({
  recipes: [],
  setRecipes: () => {},
  ingredients: [],
  setIngredients: () => {},
  categories: [],
  setCategories: () => {},
  preparations: [],
  setPreparations: () => {}
})

export default Data