import { lazy, Suspense, useState, useEffect } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'

import { ConfigProvider } from 'antd'
import huHU from 'antd/lib/locale/hu_HU'

import './styles/antd.css'
import './styles/app.sass'

import axios from 'axios'
import { api } from './consts/api'
import Data from './helpers/Data'

import Header from './layouts/Header'
import Footer from './layouts/Footer'

const Login = lazy(() => import('./pages/Login'))
const Home = lazy(() => import('./pages/Home'))
const Recipe = lazy(() => import('./pages/Recipe'))
const Ingredients = lazy(() => import('./pages/Ingredients'))
const Preparations = lazy(() => import('./pages/Preparations'))
const Cookings = lazy(() => import('./pages/Cookings'))
const NotFound = lazy(() => import('./pages/NotFound'))

const App = () => {
  const [isLogin, setIsLogin] = useState(window.location.hostname === 'localhost' ? true : false)

  const [recipes, setRecipes] = useState([])
  const [ingredients, setIngredients] = useState([])
  const [categories, setCategories] = useState([])
  const [preparations, setPreparations] = useState([])
  const [cookings, setCookings] = useState([])
  const [components, setComponents] = useState([])

  // Load data
  useEffect(() => {
    if (isLogin) {
      // Recipes
      axios.post(api.universal, {
        module: 'recipes',
        method: 'read',
        extend: {
          storage: 'mysql',
          sortby: 'Name,asc'
        }
      }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      })
      .then(res => {
        if (res.data.status.success) {
          setRecipes(res.data.content)
        }
      })

      // Ingredients
      axios.post(api.universal, {
        module: 'ingredients',
        method: 'read',
        extend: {
          storage: 'mysql',
          sortby: 'Name,asc'
        }
      }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      })
      .then(res => {
        if (res.data.status.success) {
          setIngredients(res.data.content)
        }
      })

      // Category
      axios.post(api.universal, {
        module: 'categories',
        method: 'read',
        extend: {
          storage: 'mysql',
          sortby: 'Name,asc'
        }
      }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      })
      .then(res => {
        if (res.data.status.success) {
          setCategories(res.data.content)
        }
      })

      // Preparations
      axios.post(api.universal, {
        module: 'preparations',
        method: 'read',
        extend: {
          storage: 'mysql',
          sortby: 'Name,asc'
        }
      }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      })
      .then(res => {
        if (res.data.status.success) {
          setPreparations(res.data.content)
        }
      })

      // Cooking steps 
      axios.post(api.universal, {
        module: 'cookings',
        method: 'read',
        extend: {
          storage: 'mysql',
          sortby: 'Name,asc'
        }
      }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      })
      .then(res => {
        if (res.data.status.success) {
          setCookings(res.data.content)
        }
      })

      // Components 
      axios.post(api.universal, {
        module: 'components',
        method: 'read',
        extend: {
          storage: 'mysql',
          sortby: 'Name,asc'
        }
      }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      })
      .then(res => {
        if (res.data.status.success) {
          setComponents([
            {id: 0, Name: '-- Válasszon --'},
            ...res.data.content
          ])
        }
      })
    }
  }, [isLogin])

  // Layout
  return (
    <HelmetProvider>
      <Data.Provider value={{ recipes, setRecipes, ingredients, setIngredients, categories, setCategories, preparations, setPreparations, cookings, setCookings, components, setComponents }}>
        <ConfigProvider locale={huHU}>
          <div className="flex flex-col justify-between min-h-screen">
            <BrowserRouter>
              <Header />
              <Suspense fallback="">
                {isLogin ?
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route exact path="/recept" component={Recipe} />
                  <Route exact path="/recept/:id" component={Recipe} />
                  <Route exact path="/hozzavalok" component={Ingredients} />
                  <Route exact path="/elokeszuleti-lepesek" component={Preparations} />
                  <Route exact path="/fozesi-lepesek" component={Cookings} />
                  <Route path="/" component={NotFound} />
                </Switch>
                :
                <Switch>
                  <Route path="/">
                    <Login setIsLogin={setIsLogin} />
                  </Route>
                </Switch>
                }
              </Suspense>
              <Footer />
            </BrowserRouter>
          </div>
        </ConfigProvider>
      </Data.Provider>
    </HelmetProvider>
  )
}

export default App