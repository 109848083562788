import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'

const Header = () => {
  return (
    <motion.header initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <div className="bg-gray text-center text-white text-xl font-light uppercase p-4">
        <Link to="/" className="hover:text-white">Recept szerkesztő</Link>
      </div>
    </motion.header>
  )
}

export default Header